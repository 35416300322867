import React from 'react';
import { graphql } from 'gatsby';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import {
  // BusinessFunction,
  SectionLabel,
  SectionTitle
} from 'components/Text';
import { CapabilityCard } from 'components/Cards';

import {
  ApproachDescription,
  CoverApproach,
  TextIntro,
  BusinessFunction,
  SectionIntro,
  ApproachHighlight
} from 'components/Sections';

class Page extends React.Component {
  render () {
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    const siteTitle = data.site.siteMetadata.title;
    const {
      title,
      description,
      businessFunctions,
      featuredImage,
      heading
    } = data.contentfulApproach;

    const seoProps = {
      title,
      description: description && description.childMdx.rawBody
    };
    const animationDuration = 600;
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO {... seoProps} />
        <Menu colorScheme={`dark`} />
        <Fade>
          <CoverApproach
            bgColor={`gray-800`}
            label={`Our Apporach`}
            background={{
              image: featuredImage && featuredImage.cover.fixed.src
            }}
            title={title}
          />
          <article>
            {heading && (
              <TextIntro
                label={`Discover`}
                content={heading && heading.childMdx.rawBody}
              />
            )}
            <ApproachDescription>
              <p>Our groundbreaking strategies, products and services are based on unique methods for retrieving insights. Our Researchers work in close collaboration with extraordinary Designers and Business Innovators to unravel new opportunities. There are no ordinary days at Veryday. We meet your customers in their real lives. Sometimes this takes us in to their home, in order to gain novel insights.</p>
              <p>Thanks to our in-house researchers we can act fast on megatrends and global insights to help you create and communicate products and services that truly make a difference to people’s lives. Our scientists team up with academic institutions all over the world to constantly improve our acknowledged methods and processes. Expect the unexpected.</p>
            </ApproachDescription>
            <section>
              <Container>
                <Row>
                  <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration}>
                    <SectionLabel label={`blocks[2].preTitle && blocks[2].preTitle`} />
                  </Fade>
                </Row>
                <Row>
                  <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                    <SectionIntro />
                  </Fade>
                </Row>
              </Container>
              { businessFunctions && (
                businessFunctions.length > 0 && businessFunctions.map((node, i) => {
                  const props = {
                    title: node.title,
                    animationDuration,
                    animationDelay: (i > 2 ? i - 2.5 : i) * 200,
                    src: `https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Design/How%20we%20help%20clients/design_thumb-blue_491763594_1536x1536.ashx?mw=767&car=42:25`
                  };
                  const {
                    serviceActivities
                  } = node;
                      // right={i % 2 === 0}
                  return (
                    <ApproachHighlight
                      key={node.id}
                      label={node.title}
                      bgColor={`gray-100`}
                      border={i !== businessFunctions.length - 1}
                      {...node}
                    >
                      <div key={node.id}>
                        <p>
                          We can help you reinvent the way you deliver and pilot your services to be more beneficial, accessible and desirable for your users, as well as more efficient and sustainable for your organization — increasing productivity, commercial value and customer satisfaction
                        </p>
                        {serviceActivities && (
                          <div>
                            { serviceActivities.length > 0 && (
                              <ul>
                                {serviceActivities.map((subnode) => {
                                  logger.debug(`subnode`, subnode);
                                  return (
                                    <li key={subnode.id}>{subnode.title}</li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                         )}
                      </div>
                    </ApproachHighlight>
                  );
                })
              )}
              <Container>
                <Row>
                  { businessFunctions && (
                    businessFunctions.length > 0 && businessFunctions.map((node, i) => {
                      const props = {
                        title: node.title,
                        animationDuration,
                        animationDelay: (i > 2 ? i - 2.5 : i) * 200,
                        src: `https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Design/How%20we%20help%20clients/design_thumb-blue_491763594_1536x1536.ashx?mw=767&car=42:25`
                      };
                      const {
                        serviceActivities
                      } = node;
                      return (
                          <BusinessFunction key={node.id} {...node} />
                      );
                    })
                  )}
                </Row>
              </Container>
            </section>
          </article>
        </Fade>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulApproach( slug: { eq: $slug } ){
      ...Approach
    }
  }
`;
