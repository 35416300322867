import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Color from 'color';
import { BgImg } from 'components/Elements';
import Colors from 'styles/Color';
import { Row } from 'components/GridSystem';
import {
  PrettyTitle
} from 'components/Text';
// import logger from 'utils/logger';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
// xl:mr-1
const Col = styled.div`
  ${tw`
    relative
    lg:w-full
    mb-0.5
    flex
    z-0
  `};
  &:hover,
  &:focus{
    ${tw`
      z-10
  `};
  }
  :nth-child(6n+1) {
    ${tw`
      lg:w-full
    `};
    & a > div > div {
      ${tw`
        lg:pb-1/3
      `};
    }
  }
  &:nth-child(3n+1){
    ${tw`
      xl:pr-0
    `};
  }
  &:nth-child(3n+2){
    ${tw`
      xl:pr-0
    `};
  }
  &:nth-child(3n+3){
    ${tw`
      xl:pr-0
    `};
  }
  & > a{
    ${tw`
      mb-0
  `};
  }
`;
const Card = styled(Link)`
  ${tw`
    flex-1
    flex-grow-1
    w-full
    bg-gray-800
  `};
  ${({ bgColor }) => {
    const bgColorProcessed = (bgColor.startsWith('#') || bgColor.startsWith('rgb') || bgColor.startsWith('hsl')) ? bgColor : Colors[bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      bgColor && `background-color: ${bgColorProcessed};`,
    ];
  }};
  :nth-child(1n),
  :nth-child(2n) {
    ${tw`
    `};
  }
  aside {
    ${transition}
  }
  :hover,
  :focus {
    ${tw`
      shadow-2xl
      transition-all
      transition-200
    `};
    aside {
      ${tw`
        opacity-25
      `};
    }
  }
`;
const CardContainer = styled.div`
  ${tw`
    relative
    overflow-hidden
    transition-400
    transition-ease
    h-0
    mb-0
    pb-1/3
  `}
`;
    // pb-aspect-ratio-square
const Summary = styled.p`
  ${tw`
    relative
    text-xl
    mb-0
    leading-tight
  `};
`;
const CardContent = styled.div`
  ${tw`
    absolute
    z-10
    inset-0
    flex
    content-center
    mb-0
    px-1/16
    md:px-1/8
    xl:px-1/12
  `};
`;
const ColText = styled.div`
  ${tw`
    py-0
    text-white
    w-full
    xl:w-1/3
  `};
`;
const Thumbnail = styled.div`
  ${tw`
    relative
    h-12
    block
    bg-gray-200
    pt-aspect-ratio-16/9
    md:h-full
    xl:pt-aspect-ratio-16/9
  `};
  ${({ src }) => {
    return [
      src && `
        background-image: url('${src}');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      `
    ];
  }};
`;
const Arrow = styled.p`
  ${tw`
    text-md
    font-medium
    tracking-tight
    mb-0
    pt-2
  `};
  span {
    ${tw`
      ml-2
      relative
      inline-block
      text-brand-primary-500
    `};
    vertical-align: middle;
    box-sizing: border-box;
    transform: rotate(45deg);
    &:after, &:before {
      content: "";
      box-sizing: border-box;
    }
    width: 9px;
    height: 11px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-radius: 0 1px 0 0;
    &:before, &:after{
      border-radius: 2px;
      position: absolute;
    }
    &:before{
      right: 0;
      top: -2px;
      width: 32px;
      height: 2px;
      transform: rotate(-45deg);
      transform-origin: right top;
      box-shadow: inset 0 0 0 17px;
    }
    &:after{
      width: 2px;
      height: 2px;
      left: -2px;
      top: -2px;
      box-shadow: inset 0 0 0 16px, 4px 5px;
    }
  }
`;

export default class Component extends React.Component {
  render() {
    const {
      title,
      slug,
      excerpt,
      description,
      animationDuration,
      animationDelay,
      featuredImage,
      titleRich,
      src
    } = this.props;
    const content = excerpt
      ? excerpt.childMdx.excerpt
      : description &&
      description.childMdx.excerpt;

    const Animation = withReveal(
      CardContainer,
      <Fade duration={animationDuration} distance={`20%`} />
    );

    return (
      <Col>
        <Card to={`/our-work/${slug}`} bgColor={`brand-primary`}>
          <Animation>
            <CardContent>
              {featuredImage && <BgImg {...featuredImage.thumbnail} mask={0.1} />}
              <Row>
                <ColText>
                  {titleRich && (
                    <MDXProvider
                      components={{
                        p: PrettyTitle
                      }}
                    >
                      <MDXRenderer>
                        {titleRich.childMdx.body}
                      </MDXRenderer>
                    </MDXProvider>
                  )}
                  <Summary>
                    {title}
                  </Summary>
                </ColText>
              </Row>
            </CardContent>
          </Animation>
        </Card>
      </Col>
    );
  }
}
Component.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  src: PropTypes.string,
  to: PropTypes.string.isRequired,
  animationDuration: PropTypes.number.isRequired,
  animationDelay: PropTypes.number.isRequired
};
Component.defaultProps = {
  title: 'Case Design',
  content: 'Barotse acriflavin anagogic nematocystic semipyritic metochous plagiarist prelatry dynamite constructionist repasser environment louk snot  knobber risibles',
  src: ''
};
