import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import {
  BgImg
} from 'components/Elements';
import { Row } from 'components/GridSystem';
// import logger from 'utils/logger';

      // xl:mr-1
const Col = styled.div`
  ${tw`
    relative
    w-full
    lg:w-1/3
    mb-0
    flex
    z-0
    pb-1
    xl:pb-3
  `};
  &:hover,
  &:focus{
    ${tw`
      z-10
  `};
  }
  &:nth-child(3n+1){
    ${tw`
      xl:pr-1.5
    `};
  }
  &:nth-child(3n+2){
    ${tw`
      xl:px-0.75
    `};
  }
  &:nth-child(3n+3){
    ${tw`
      xl:pl-1.5
    `};
  }
  & > a{
    ${tw`
      mb-0
  `};
  }
`;
const Card = styled(Link)`
  ${tw`
    bg-white
    flex-1
    flex-grow-1
    w-full
  `};
  :nth-child(1n),
  :nth-child(2n) {
    ${tw`
    `};
  }
  :nth-child(odd) {
    ${tw`
    `};
  }
  :hover,
  :focus {
    ${tw`
      shadow-2xl
      transition-all
      transition-200
    `};
  }
`;
const CardContainer = styled.div`
  ${tw`
    transition-400
    transition-ease
    h-full
  `}
`;
const Title = styled.h4`
  ${tw`
    text-hero
    font-light
    px-1/16
    md:px-1/8
    xl:px-3.5
  `};
`;
const Content = styled.p`
  ${tw`
    mb-0
    text-md
    font-light
    tracking-wide
    leading-fit
    px-1/16
    md:px-1/8
    xl:px-3.5
  `};
`;
const ColImg = styled.div`
  ${tw`
    w-full
    md:w-1/2
    lg:w-full
  `};
`;
const ColText = styled.div`
  ${tw`
    py-2
    md:py-4
    lg:py-3
    md:w-1/2
    lg:w-full
  `};
`;
const Thumbnail = styled.div`
  ${tw`
    relative
    h-12
    block
    bg-gray-200
    pt-aspect-ratio-16/9
    md:h-full
    xl:pt-aspect-ratio-16/9
    mb-0
  `};
  ${({ src }) => {
    return [
      src && `
        background-image: url('${src}');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      `
    ];
  }};
`;
const Arrow = styled.p`
  ${tw`
    text-md
    font-medium
    tracking-tight
    mb-0
    pt-2
  `};
  span {
    ${tw`
      ml-2
      relative
      inline-block
      text-brand-primary-500
    `};
    vertical-align: middle;
    box-sizing: border-box;
    transform: rotate(45deg);
    &:after, &:before {
      content: "";
      box-sizing: border-box;
    }
    width: 9px;
    height: 11px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-radius: 0 1px 0 0;
    &:before, &:after{
      border-radius: 2px;
      position: absolute;
    }
    &:before{
      right: 0;
      top: -2px;
      width: 32px;
      height: 2px;
      transform: rotate(-45deg);
      transform-origin: right top;
      box-shadow: inset 0 0 0 17px;
    }
    &:after{
      width: 2px;
      height: 2px;
      left: -2px;
      top: -2px;
      box-shadow: inset 0 0 0 16px, 4px 5px;
    }
  }
`;

export default class Component extends React.Component {
  render () {
    const {
      title,
      slug,
      excerpt,
      description,
      animationDuration,
      animationDelay,
      featuredImage,
      src
    } = this.props;
    const titleSplit = title && title.split(` `);
    const content = excerpt
      ? excerpt.childMdx.excerpt
      : description &&
        description.childMdx.excerpt;

    const Animation = withReveal(
      CardContainer,
      <Fade bottom duration={animationDuration} distance={`20%`} delay={animationDelay} />
    );

    return (
      <Col>
        <Card to={`/capability/${slug}`}>
          <Animation>
            <Row>
              <ColImg>
                <Thumbnail src={src}>
                  { featuredImage && <BgImg {...featuredImage.thumbnail} /> }
                </Thumbnail>
              </ColImg>
              <ColText>
                <Title>
                  <b>
                    {titleSplit && titleSplit[0]}
                  </b>
                  <br />
                  {titleSplit && titleSplit[1]}
                </Title>
                <Content>
                  {content}
                  <Arrow>
                    Learn More
                    <span />
                  </Arrow>
                </Content>
              </ColText>
            </Row>
          </Animation>
        </Card>
      </Col>
    );
  }
}
Component.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  src: PropTypes.string,
  to: PropTypes.string.isRequired,
  animationDuration: PropTypes.number.isRequired,
  animationDelay: PropTypes.number.isRequired
};
Component.defaultProps = {
  title: 'Capability Design',
  content: 'Barotse acriflavin anagogic nematocystic semipyritic metochous plagiarist prelatry dynamite constructionist repasser environment louk snot  knobber risibles',
  src: ''
};
